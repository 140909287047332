$c-primary: #c7b170;
$c-secandary: #c7b170;
$dots: false;
$version: 3.1;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'SacGot', sans-serif;

      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'SacGot';
        src: url('/fonts/CrownSacGot-Hea.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SacGot';
        src: url('/fonts/CrownSacGot-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SacGot';
        src: url('/fonts/CrownSacGot-Medium.otf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-BoldItalic.otf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Italic.otf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-LightItalic.otf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/fonts/SourceSansPro-Black.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/fonts/SourceSansPro-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/fonts/SourceSansPro-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  background-color: var(--page-background-color);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 34;

  & > div {
    padding: 2rem;
  }
  .heading {
    font-family: var(--heading-font-family) !important;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 31px;
    color: var(--primary);
    letter-spacing: -0.5px;
  }

  .title {
    font-size: 14px;
  }

  .spinner1 {
    margin-bottom: 40px;
    margin-left: 23px;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    box-shadow: 24px 24px var(--primary), -24px 24px var(--loading-spinner-evendot),
      -24px -24px var(--primary), 24px -24px var(--loading-spinner-evendot);
    -webkit-animation: spin ease infinite 2s;
  }
  @-webkit-keyframes spin {
    0%,
    100% {
      box-shadow: 24px 24px var(--primary), -24px 24px var(--loading-spinner-evendot),
        -24px -24px var(--primary), 24px -24px var(--loading-spinner-evendot);
    }
    25% {
      box-shadow: -24px 24px var(--loading-spinner-evendot), -24px -24px var(--primary),
        24px -24px var(--loading-spinner-evendot), 24px 24px var(--primary);
    }
    50% {
      box-shadow: -24px -24px var(--primary), 24px -24px var(--loading-spinner-evendot),
        24px 24px var(--primary), -24px 24px var(--loading-spinner-evendot);
    }
    75% {
      box-shadow: 24px -24px var(--loading-spinner-evendot), 24px 24px var(--primary),
        -24px 24px var(--loading-spinner-evendot), -24px -24px var(--primary);
    }
  }
}
