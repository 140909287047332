$c-primary: #c7b170;
$c-secandary: #c7b170;
$dots: false;
$version: 3.1;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'SacGot', sans-serif;

      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'SacGot';
        src: url('/fonts/CrownSacGot-Hea.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SacGot';
        src: url('/fonts/CrownSacGot-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SacGot';
        src: url('/fonts/CrownSacGot-Medium.otf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-BoldItalic.otf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Italic.otf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-LightItalic.otf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/fonts/SourceSansPro-Black.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/fonts/SourceSansPro-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/fonts/SourceSansPro-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$btn-text: white;
@if variable-exists(btn-text-overrided) {
  $btn-text: $btn-text-overrided;
}

.btn {
  border: 1px solid var(--primary);
  background: var(--primary);
  display: inline-block;
  color: $btn-text;
  height: 48px;
  min-height: 48px;
  font-family: var(--base-font-family) !important;
  border-radius: 4px;
  padding: 2px 17px 4px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  font-weight: 500;
  &.rounded {
    border-radius: 30px;
  }

  &.large {
    height: 60px;
    min-height: 60px;
    padding-top: 0px;
  }

  &.full {
    width: 100%;
  }

  &.shadow {
    box-shadow: 0px 5px 20.16px 3.84px lighten(#000, 87%);
  }

  &.secandary {
    background: var(--secondary);
    border-color: var(--secondary);
  }

  &.transparent {
    background: transparent;
    border-color: transparent;
    color: var(--secondary);
    padding: 0;
    width: auto !important;
  }

  &.outline {
    background: none;
    color: var(--secondary);
    border-color: var(--secondary);
    box-shadow: none;
  }

  &.outline.secandary {
    color: var(--secondary);
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &:active {
    box-shadow: none;
    opacity: 0.9;
  }

  &[disabled] {
    opacity: 0.3;
  }
}
