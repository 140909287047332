$c-primary: #c7b170;
$c-secandary: #c7b170;
$dots: false;
$version: 3.1;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'SacGot', sans-serif;

      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'SacGot';
        src: url('/fonts/CrownSacGot-Hea.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SacGot';
        src: url('/fonts/CrownSacGot-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SacGot';
        src: url('/fonts/CrownSacGot-Medium.otf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-BoldItalic.otf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Italic.otf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-LightItalic.otf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/fonts/SourceSansPro-Black.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/fonts/SourceSansPro-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/fonts/SourceSansPro-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$primary: var(--primary);
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  text-align: left;

  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    margin-bottom: 1.5rem; //24
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 1.1;
    letter-spacing: -0.03125rem; //-0.5px
  }
  .description {
    text-align: left;
    margin-bottom: 1.5rem; //24
  }
  .container {
    max-width: 440px;
    .userDetailRow {
      position: relative;
      & > span {
        &:first-child {
          position: absolute;
          left: 0;
          top: 0;
          width: 30%;
          font-weight: bold;
          font-size: 1rem; //16
          line-height: 1.2;
          z-index: 1;
          height: 3.75rem; //60
          display: flex;
          align-items: center;
        }
      }
      .address-label-span {
        height: 5rem !important; //80
      }
    }
    .check-confirm {
      margin-top: 2.5rem;
      margin-bottom: 1rem;
    }
  }

  .confirm-name-empty {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $c-danger;
  }

  .error {
    color: $c-danger;
    font-size: 0.75rem; //12
  }

  .input {
    .halfWith {
      max-width: 50%;
      margin-right: 1rem !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
