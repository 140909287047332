$c-primary: #c7b170;
$c-secandary: #c7b170;
$dots: false;
$version: 3.1;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'SacGot', sans-serif;

      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'SacGot';
        src: url('/fonts/CrownSacGot-Hea.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SacGot';
        src: url('/fonts/CrownSacGot-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SacGot';
        src: url('/fonts/CrownSacGot-Medium.otf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-BoldItalic.otf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Italic.otf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-LightItalic.otf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProspectusStd';
        src: url('/fonts/ProspectusStd-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/fonts/SourceSansPro-Black.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/fonts/SourceSansPro-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/fonts/SourceSansPro-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  .error {
    font-size: 12px;
    color: red;
    padding: 7px 15px;
  }
}
